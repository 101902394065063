import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Signup() {
  const [email, setEmail] = useState('');
  const [records, setRecords] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState('');

  useEffect(() => {
    const handleTurnstileCallback = () => {
      if (window.turnstile) {
        window.turnstile.render('.cf-turnstile', {
          sitekey: process.env.REACT_APP_SITEKEY,
          theme: "light",
          callback: (token) => setTurnstileToken(token) 
        });
      }
    };

    if (typeof window.turnstile === 'undefined') {
      const script = document.createElement("script");
      script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
      script.async = true;
      script.onload = handleTurnstileCallback;
      document.body.appendChild(script);
    } else {
      handleTurnstileCallback();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);

    if (!turnstileToken) {
      toast.error('Please complete the captcha');
      setIsFetching(false);
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND + '/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'cf-turnstile-response': turnstileToken 
        },
        body: JSON.stringify({ 
          email
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw errorData.info;
      }

      toast.success('User Created', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      const data = await response.json();
      setRecords(data);

    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('There was a problem with your fetch operation:', error);
      setTurnstileToken('');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } finally {
      setIsFetching(false);
    }
  };

  if (isFetching) {
    return <div>Processing...</div>;
  }

  if (records) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-3"></div>
          <div className="col">
            <h1 className="h2">Signup</h1>
            <em className="mb-1">Please check your email to confirm your account</em><br />
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-3"></div>
        <div className="col">
          <h1 className="h2">Signup</h1>
          <form onSubmit={handleSubmit}>
            <input className="form-control" type="email" value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email" required />
            <br />
            <div className="cf-turnstile"></div>
            <br />
            <button type="submit" className="btn btn-primary">Send</button>
          </form>
          <ToastContainer />
        </div>
        <div className="col-4"></div>
      </div>
    </div>
  );
}

export default Signup;