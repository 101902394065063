import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Verification() {
  let params = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND + '/auth/verification/' + params.otp, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error:', error));
  }, [params.otp]);


  return (

    <div >
      <main>
        <div className="container">
          <div className="text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
            <h1 className="h2">Verification</h1>
          </div>
          <div className="text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
            <h5>{data ? <div>{data.info}
              <br />
              <br />
              <Link to="https://3vidence.com/app" class=" link-info">
                Login
              </Link></div> : <p>Loading data...</p>}</h5>
          </div>

        </div>
      </main>
    </div>

  );
}

export default Verification;